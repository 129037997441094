import React, { useState, useEffect } from "react";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Alert, Popconfirm, Row, Col, Button, Switch, Tabs, Space, message, Select } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import RebutsBancExportaExcel from './RebutsBancExportaExcel';
import RebutsAltresExportaExcel from './RebutsAltresExportaExcel';

import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();

function RebutsGeneraExportacions(props){

  const [dadesRebutsBanc, setDadesRebutsBanc] = useState([]);
  const [dadesRebutsAltres, setDadesRebutsAltres] = useState([]);

  const [isLoadingBanc, setIsLoadingBanc] = useState(false);
  const [isLoadingAltres, setIsLoadingAltres] = useState(false);
  const [canviarEstatAlGenerar, setCanviarEstatAlGenerar] = useState(true);
  const [idEmpresa, setIdEmpresa] = useState(1);


  const dadesRebutsExcelBanc = async () =>{

    setIsLoadingBanc(true);
    console.log('idEmpresa', idEmpresa)
    const rebutsBanc = await Api.get(`/rebuts/fitxer/excel/banc/${idEmpresa}`);
    if(rebutsBanc.result.success===1){
      setDadesRebutsBanc(rebutsBanc.data.rebuts);
      console.log(rebutsBanc.data.rebuts)
      let dades = {
        estat: 'enviat',
        ids_rebuts: rebutsBanc.data.llista_ids
      }
      if(canviarEstatAlGenerar){
        const resposta = await Api.myFetch(`/rebuts/estat/enviat`, 'PUT', JSON.stringify(dades));
        if(props.callback){
            props.callback();
        }
      }
    }
    setIsLoadingBanc(false);

  }

  const dadesRebutsExcelAltres = async () =>{
    setIsLoadingAltres(true);
    const rebutsAltres = await Api.get(`/rebuts/fitxer/excel/altres/${idEmpresa}`);
    if(rebutsAltres.result.success===1){
      setDadesRebutsAltres(rebutsAltres.data.rebuts);
    }
    setIsLoadingAltres(false);
  }

  const triaIdEmpresa = (value) =>{
    setIdEmpresa(value);
  }

  return (
      <>
      <Select
        defaultValue={idEmpresa}
        onChange={triaIdEmpresa}
        options={[
          {
            value:1,
            label:'Empresa Normal',
          },
          {
            value:2,
            label:'Empresa Roba',
          },
        ]}
      />

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Banc" key="1">
          <Row gutter={[12,12]}>
            <Col span={24}>
            {!canviarEstatAlGenerar ? null :
              <Alert
              message="Alerta Exportar Banc"
              description="Al usar el botó de 'Generar Excel Banc' tots els rebuts que siguin de tipus pagament BANC que tinguin l'estat com a PENDENT s'inclouran en el fitxer i es modificarà l'estat a ENVIAT. Pots optar per no modificar l'estat amb l'interruptor que hi ha al costat del botó."
              type="warning"
              showIcon
              />
            }
            </Col>
            <Col span={14}>
              <Space align="center">
                <Switch checkedChildren="Modificar Estat" unCheckedChildren="NO Modificar Estat" defaultChecked onChange={(checked) => setCanviarEstatAlGenerar(checked)}/>
                <Button type="secondary" onClick={() => dadesRebutsExcelBanc()} loading={isLoadingBanc}>
                  Generar Excel Banc
                </Button>
              </Space>
            </Col>
            <Col span={10}>
              {dadesRebutsBanc.length ==0 ? null :
                  <RebutsBancExportaExcel dades={dadesRebutsBanc} btn_txt="Rebuts BANC" nom_fulla="Rebuts" nom_fitxer={'Rebuts_BANC'}/>
               }
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Altres" key="2">
          <Row gutter={[12,12]}>
            <Col span={12}>
              <Button type="secondary" onClick={() => dadesRebutsExcelAltres()}  loading={isLoadingAltres}>
                Generar Excel Altres
              </Button>
            </Col>
            <Col span={12}>
               {dadesRebutsAltres.length ==0 ? null :
                  <RebutsAltresExportaExcel dades={dadesRebutsAltres} btn_txt="Rebuts ALTRES" nom_fulla="Rebuts" nom_fitxer={'Rebuts_ALTRES'}/>
               }
             </Col>
           </Row>
        </Tabs.TabPane>
      </Tabs>
      </>
  );

}

export default translate(RebutsGeneraExportacions);
